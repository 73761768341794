

.failed{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: blue;
}

#project-page{
  background-color: var(--main-grey-color);
  position: absolute;

  width: 100vw;
  overflow-x: hidden
}

#project-page .project-header{

  height: 50vh;
  width: 100vw;
  top:0;
  left: 0;
  position: fixed;
  z-index: 10;
}

#project-page .project-header .project-title{

  position: absolute;
  z-index: -1;
  left: 20px;
  top: 100px;
  font-size: 110pt;
  color: white;
  font-family: var(--type-fam-wide);
  font-weight: 600;
  line-height: .8;
  font-style: normal;
  letter-spacing: -1pt;
  text-transform: uppercase;

}

#project-page .project-header .project-x{
  position: fixed;
  top: 20px;
  left: 20px;
  height: 25px;
  width: 25px;
  transform: rotate(-135deg);
}


#project-page .project-body{

  height: auto;
  width: 100%;
  position: relative;
  margin-top: calc(100vh - 100px);

}

#project-page .project-body .project-thumbnail{
  position: relative;
  margin-top: -600px;
  margin-left: auto;
  margin-right: 40px;
  height: 80vh;
  width: 40vw;
  transform: rotate(90deg);
  box-shadow: 2px 2px 15px rgba(10,10,11,1);
  z-index: 0;

}

#project-page .project-body .project-thumbnail img{
  position: relative;
  height: auto;
  width: 100%;
  background-size: auto auto;

}

#project-page .project-header .project-details{
  position: fixed;
  left: 20px;
  top: 600px;
  font-family: 'acumin-pro', sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  text-align: left;
  font-size: 14pt;
  color: white;

}

#project-page .project-header .bio{
  position: relative;
  width: 30vw;
  left: 25px;
  top: 250px;
  font-family: 'acumin-pro', sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  text-align: left;
  font-size: 14pt;
  color: white;
}

#project-page .project-body .project-content{
  box-shadow: 0px -2px 15px rgba(10,10,11,.2);
  z-index: 15;
  position: relative;
  background-color: white;
  width: 100%;
  height:auto;
}
#project-page .project-body .pic-container{
  position: relative;
  width: 25vw;
  height:400px;
  margin-top: 100px;
}
#project-page .project-body .data-container .pic-box{
  position: absolute;

  height: 500px;
  width: 350px;
  top:80px;
  left:0px;
}

 #project-page .project-body .data-container .pic-box .pic{
  position: relative;
  height: auto;
  width: 100%;
  background-size: auto auto;
}

#project-page .project-footer{

  height: 50vh;
  position: relative;
}

.next-project{
  position: relative;
}

.prev-proj{
  position: relative;
}
.arrow{
  height: 30px;
  width: 30px;

}
