@import url("https://use.typekit.net/aow6xks.css");

:root{
  --main-grey-color: rgba(25, 24, 23);
  --marg: 20px;
  --type-fam-reg: 'acumin-pro-wide', sans-serif;
  --type-fam-serif: 'baskerville-poster-pt', serif;
  --block-top: 24px;
  --type-size-h1: 110pt;
  --type-size-h1-outline: 90pt;
  --type-size-h2: 40pt;
  --type-size-h3: 10pt;
  --type-size-body:29pt;
  --thumb-width: 900px;
  --thumb-height: 600px;
  --marg-proj: 100px;
  --grid-size: 800px;
  --letter-spacing: -10px;
  --bio-size: 70vw;
  --icon-size: 100px;

}

* {
  box-sizing: border-box;
  cursor: hidden;

}





html, body {
  width:100vw;
  margin: 0px;

  overflow-x: hidden;
  background-color: var(--main-grey-color);
}
@media only screen  and (max-width: 350px) {

}

@media only screen  and (max-width: 650px) {
  :root{
    --type-size-h1-outline: 40pt;
    --type-size-h1: 60pt;
    --type-size-h2: 22pt;
    --thumb-width: 375px;
    --thumb-height: 250px;
    --marg-proj: 30px;
    --grid-size: 80vh;
    --letter-spacing: -5px;
    --bio-size:80vw;
    --type-size-body: 12pt;
    --icon-size: 80px;
    --marg:8px;
    --type-size-h3: 8pt;

   }
   #navbar .navNav{
     opacity: 0;
   }

   #fixed .intro{
     transform: rotate(90deg);
     padding-top: 13vh;
     padding-left: 25vw;
   }


   #fixed .intro .scroll-icon{
     left:auto;
     left: 100px;
     top:15vh;
     height: 80px;
     width: 80px;
     transform: rotate(-90deg);
   }

   #navbar .mainNav {
     width:50px!important;
   }

   #fixed .name h1{
     top: 0px!important;
     left: 80px!important;
     font-size: 10pt!important;
     letter-spacing: 0px!important;
     line-height: 1!important;
   }

   #projects .list .project-container .title{
     bottom: -10px!important;
     top: auto!important;
     right:auto!important;
     left:10px!important;
   }

   #about .about-me-second{
     margin-right:auto!important;
     margin-left: 20px!important;
   }











}
@media only screen and (max-width: 900px){
  #about .contact-list{
    margin-top: 50px!important;


    display: grid!important;
    width: calc(var(--thumb-width) - 40px);

  }

    #about .contact-list li{
      margin-top: 10px;
    }

    #footer .top-button{
      margin-top: 10px;
    }
}

@media only screen and (max-width: 900px) and (min-width: 651px) {
  :root{
    --type-size-h1-outline: 60pt;
    --type-size-h1: 70pt;
    --type-size-h2: 30pt;
    --thumb-width: 525px;
    --thumb-height: 350px;
    --marg-proj: 40px;
    --grid-size: 600px;
    --bio-size:90vw;
    --type-size-body: 20pt;
    --marg:15px;
    --type-size-h3: 9pt;
   }

   #fixed .intro .intro-portfolio{
     bottom: -120px!important;
     right:-50px!important;
   }

}

@media only screen  and (max-width: 1150px) and (min-width: 901px){
  :root{
    --type-size-h1-outline: 80pt;
    --type-size-h1: 100pt;
    --type-size-h2: 35pt;
    --thumb-width: 750px;
    --thumb-height: 500px;
    --marg-proj: 60px;
    --grid-size: 700px;
    --marg:18px;
   }
}


::-webkit-scrollbar {
    display: none;
}



.load{
  position: fixed;
  -webkit-position: sticky;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background-color: var(--main-grey-color);
  overflow: hidden;
  pointer-events: none;
}

.load img{
  margin-right: 35%;
  margin-left: 35%;
  margin-top: 30vh;
  margin-bottom: auto;
  width: auto;
  position: absolute;
  height: auto;
}

.scroll-bar{
  position: absolute;
  right: -2px;
  top: 0px;
  background-color: white;
  width: 3px;
  height: 8px;
  z-index: 60;
  position: fixed;
  -webkit-position: sticky;
}


#navbar a {
  text-decoration: none;
  color: inherit;

}


a:visited { text-decoration: none;}
a:hover { text-decoration: none;}
a:focus { text-decoration: none;}
a:hover, a:active { text-decoration: none;}



#website {
  width: 100vw;
  height: auto;
}

.cursor{
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 999;
  box-shadow: 2px;

  pointer-events: none;

  background-color: rgba(250,250,250,.95);
  /*border: .9px dashed;*/

  border-radius: 50%;
  /*animation: cursorAnim 20s infinite;*/

}

@media (hover: none) {
  .cursor{
    opacity: 0;
    height: 0;
    width: 0;
  }
}

#mobile {
  opacity: 0;
  position: fixed;
  left:0px;
  top:0px;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.9);
  overflow: hidden;

}

#mobile .mobileNav{
  width: 50vw;
  position: relative;
  margin-top: 20vh;
  margin-left:auto;
  margin-right: auto;
  font-family: var(--type-fam-reg);
  list-style: none;
  font-size: 40pt;
  text-align: center;

}
#mobile .mobileNav li{
  padding-bottom: 10vh;

}
#mobile .mobileSocail span{
  height: 1px;
  width: 60px;
  position: relative;
  margin-left: auto;
  margin-right:auto;
  background-color: white;
}
#mobile .mobileSocail{
  list-style: none;
  font-size: 10pt;
  font-family: var(--type-fam-serif);
  font-style: italic;
  text-align: center;
}
#mobile .mobileSocail li{
  padding-bottom: 15px;
}


#navbar {
  position: fixed;
  -webkit-position: sticky;
  float:left;
  background-color: none;
  height: 65px;
  width: 100%;
  font-size: 10pt;
  color: white;
  font-family: var(--type-fam-reg);
  text-transform: uppercase;
  font-weight: 300;
  font-style: normal;
  letter-spacing: .5px;
  z-index: 100;
  display: flex;
  top:0;

}

#navbar .border{
  background-color: var(--main-grey-color);
}


#navbar .mainNav {
  width:60px;
  height:100%;
  position: absolute;
  margin-left: 20px;
  margin-top: 15px;
  display: table-cell;
  align-items: center;
  text-align: left;

}

#navbar .mainNav img {
  background-repeat: no-repeat;
}

#navbar .mainNav p {
  opacity:0;
}

#navbar .navNav{

  position: absolute;
  top: 35px;
  display: table-cell;
  margin-left: auto;
  right: 200px;
  display:flex;
  list-style:none;
  align-items: stretch;
  justify-content: space-between;
  width: 200px;

}

#navbar .circle{
  pointer-events: none;
  opacity: 0;
  top:-10px;
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgba(255,152,250,1);;
}

#navbar .navNav li{
  display: block;
  flex: 0 1 auto;
  list-style-type: none;
}

#navbar .hamNav{
  position: relative;
  margin-left: auto;
  margin-right:2px;
  margin-top: 10px;
  width:100px;
  height: 70px;
  z-index: 999;
}

#navbar .hamNav .line-one{
  position: absolute;
  top:20px;
  right:25px;
  width:40px;
  height: 1px;
  background-color: white;
}

#navbar .hamNav .line-two{
  position: absolute;
  top:38px;
  right:28px;
  width:40px;
  height: 1px;
  background-color: white;
}


#fixed{
  position: fixed;
  left: 0px;
  top: 0;
  height: 100vh;
  width: 100vw;


}
#fixed .intro-grid{



  display: block;
  padding-top: 50px;
  position: relative;
  margin:auto;
  height: auto;
  width: var(--grid-size);
  opacity: .99;

}

#fixed .chrome-type{
  opacity: 1;
  position: relative;

  height: auto;
  width:var(--thumb-width);
  margin-top: 2px;


}

#fixed .background-circle{
  position: relative;
  top:-200px;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  width: 100vh;
  border-radius: 50%;
  background-color: var(--main-grey-color);
  z-index: 0;
}

#fixed .name h1{
  opacity: 1;
  font-size: 14pt;
  color: rgba(255,250,240,1);
  font-family: var(--type-fam-reg);
  font-weight: 300;
  line-height: .9;
  font-style: normal;
  letter-spacing: 0;
  text-transform: uppercase;
  font-kerning: auto;
  width:50px;
  position: absolute;
  left: 90px;
  top:-10px;
}

#fixed .name p{
  z-index: 20;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255,250,240,1);
  opacity: 1;
}


#fixed .intro{
  font-family: var(--type-fam-reg);
  font-size: var(--type-size-h1);
  text-align: right;
  position: relative;
  height: calc(var(--grid-size)/2);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  width:var(--grid-size);
  text-transform: uppercase;
  color: white;
  font-weight: 400;
  background-image: "/src/images/icons/Grid.svg"


}


#fixed .intro .intro-portfolio{
  position: absolute;
  bottom: -500px;
  right: -100px;
  font-family: var(--type-fam-reg);
  letter-spacing: var(--letter-spacing);
  margin-left: auto;
  margin-right: 40px;
  line-height: 1;
  font-weight: 500;

}

#fixed .intro .intro-portfolio span{
  background-color:white;
  height: .0px;
  width:1000px;
  position: absolute;
  margin-top:40px;
  right:-100px;
}

#fixed .intro-blur{
  opacity: 0;
  position: absolute;
  z-index: -1;
  left:200px;
  top:10px;
  height: 0px;
  width:0px;

}

#fixed .scroll-icon{
  position: absolute;
  top:0px;
  left:-40px;
  height: 100px;
  width: 100px;

}


#page{
  z-index: 0;
  height: 400vh;
  width: 100vw;
  position: absolute;
  display: grid;

}

#home{

  grid-row-start: 1;
  margin-top: 100vh;
  height: 200vh;

  width: 100vw;
  position: relative;
  font-family: var(--type-fam-reg);
  font-size: 15pt;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  font-style: normal;
  text-transform: uppercase;



}





#home .start-container{
  position: absolute;
  right:70px;
  padding-right: 20px;
  margin-top: 620px;

  display: grid;

}

#home .start-container .check-it{
  width: 100px;

  grid-row-start: 1;
  text-align: right;

}

#home .start-container .arrow-white{
  top:-20px;
  transform: rotate(180deg);
  margin-left: 55px;
  position: relative;
  grid-row-start: 2;
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-size: 80% auto;
  z-index: 5;
  opacity: 1;
}







.logo{

  margin-left: auto;
  margin-right: auto + 9%;
  margin-top: 20px;
  height: 100%;
  width: 100%;
  position: relative;
}

.logo img{
  opacity: 0;
  z-index: 101;
  position: absolute;
  width:70%;
  top:30px;
  left: 10%;
}
.logo img:nth-of-type(2){
  opacity: 0;
}


/*--------------------PROJECTS--------------------*/


#projects{
  padding-top: 100px;
  background-color: none;
  grid-row-start: 2;
  position: relative;
  width: auto;
  height: auto;

}

#projects .project-header {
  font-family: var(--type-fam-reg);
  font-size:var(--type-size-h1-outline);
  color: rgba(0,0,0,0);
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  margin-left: var(--marg);
  margin-top: 380px;
  -webkit-text-stroke: .75px white;
  letter-spacing: var(--letter-spacing);
  width: 200px;
  line-height: .9;

}

/*#projects .project-header span{
  position: absolute;
  top:80px;
  left: 780px;
  width:470px;
  height: 3px;
  background-color: white;
}*/



.list{
  margin-top: -100px;
  position: relative;
  width: 100%;
  height: 100%;
}

.project-container{
  margin-top: var(--marg-proj);

  width: 100vw;
  height: var(--thumb-height);

  position: relative;
  pointer-events: none;



}

.list .project-container .title{
  font-size: var(--type-size-h2);
  color: white;
  font-family: var(--type-fam-serif);
  font-style: italic;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  z-index: 201;
  opacity: 1;
  position: absolute;
  top:40%;
  right:30px;
  width: 50px;


}

.list .project-container .order {
  font-size: 135pt;

  font-family: 'acumin-pro', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -5px;
  color: rgba(0,0,0,0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  position: absolute;
  top:90px;
  left:33%;
  width: 30%;
  opacity: 0;

}



.list .project-container .arrow{
  position: absolute;
  top: calc(115% - 80px);
  left: calc(10% + 835px);
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: 50% auto;
  z-index: 950;
  opacity: 0;

}

.list .project-container .detail-wrapper{
  opacity: 0;
  font-family: var(--type-fam-reg);
  z-index: 10;
  font-size: var(--type-size-h3);
  text-transform: uppercase;
  font-weight: 300;
  color: white;
  position: relative;
  height: 100%;
  width: 50px;
  top: 0px;
  margin-right:-30px;
  margin-left:auto;
}

.list .project-container .category {


  height: 1px;
  width: 2px;
  transform: rotate(-90deg);
  position: absolute;
  bottom: 0px;
}

.list .project-container .year {

  top:20px;
  height: 1px;
  width: 2px;
  transform: rotate(-90deg);
  position: absolute;

}

.list .project-container .image-box{

  pointer-events: all;
  position: absolute;
  z-index: 200;
  height: var(--thumb-height);
  width: var(--thumb-width);
  top:80px;
  left:0px;

  /*box-shadow: 2px 2px 15px rgba(10,10,11,1);*/


  font-size: 10pt;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
}

.list .project-container .text-box{
  pointer-events: none;
  position: absolute;

  height: var(--thumb-height);
  width: var(--thumb-width);
  top:80px;



}

.list .project-container .text-box span{

  position: absolute;
  height: 1px;
  width: 300px;
  bottom: 0;
  background-color: white;
  opacity: 0;
}
.list .project-container .thumbnails{
  opacity: 1;
  position: relative;
  z-index: 500;
  height: 100%;
  width: auto;
  background-size: auto auto;
}



@media only screen and (min-width: 750px) {

  .project-container:nth-child(odd){

    margin-left:auto;
    margin-right: 0;

  }


  .list .project-container:nth-child(odd) .order{
    text-align: right;
    left: auto;
    right: 33%;
  }

 .list .project-container:nth-child(odd) .image-box{
    left:auto;
    right: 0px;
  }
  .list .project-container:nth-child(odd) .text-box{
     left:auto;
     right: 0px;
   }

   .list .project-container:nth-child(odd) .title{
      left:-90px;
      right:auto;

    }
  .list .project-container:nth-child(odd) .detail-wrapper{
    margin-right:auto;
    margin-left:0px;
  }





}


.archive-wrap{

  pointer-events: visible;
  position: absolute;
  top:600px;
  left: 0px;
  height:100px;
  width: 100px;

}
 .archive-wrap .archive-btn{
  opacity: 0;
  position: absolute;

  height:80%;
  width: 80%;

  background-repeat: no-repeat;

}

.archive-wrap .resume-btn{
 opacity: 0;
 position: absolute;

 height:80%;
 width: 80%;
 z-index:20;
 background-repeat: no-repeat;

}
.archive-wrap .blob{
  border-radius: 50%;
  background-color: rgba(255,152,250,1);;
  height:20px;
  width: 20px;
  opacity: 0;
  position:relative;
  margin-right:auto;
  margin-left: auto;

}


@media only screen and (max-width: 768px) {
  .list .project-container .wrapper .thumbnails{
    position: absolute;
    height: 50vh;
    width: auto;
    top:0px;
    left: 2px;
  }
}

.archive-page{
  -webkit-position: sticky;
  position: fixed;
  overflow: scroll;
  top: 0px;
  left: 0px;
  z-index: 200;
  background-color: white;
  height: 100%;
  width: 100%;

}

.archive-page .x{
  position: absolute;
  background-color: black;
  height: 20px;
  width: 20px;
  top: 40px;
  right: 40px;
}



/*-----------------ABOUT--------------------------*/

#about{
  padding-top: 40vh;
  grid-row-start: 3;
  position: relative;
  width: 100vw;
  height: auto;
  padding-bottom: 20px;
}

#about .section-one{
  margin-top: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

#about .about-me-first{
  font-family: var(--type-fam-reg);
  position: relative;
  margin-top: -20px;
  margin-left: calc(var(--marg));
  margin-right: auto;
  line-height: 1.2;
  text-transform: uppercase;
  width: var(--bio-size);
  font-size: var(--type-size-body);
  text-align: left;
  font-weight: 300;
  color:white;
  padding-bottom: 5vh;

}

#about .about-me-first p::after {
  content: ""
}

#about .meimage{
  opacity: 1;
  width:var(--thumb-width);
  height:var(--thumb-height);



  /*box-shadow: 2px 2px 15px rgba(10,10,11,1);*/

  position: relative;

  padding-top: 20px;
  margin-left: 0px;
  background-size: auto var(--thumb-width);
  background-image: url('./images/me.jpg');
  background-repeat: trepeat;

}

#about .section-one em{
  font-family: var(--type-fam-serif);
  font-style: italic;
}

#about .about-me-second{

  font-family: var(--type-fam-serif);
  font-style: italic;
  position: relative;
  margin-top: calc(var(--thumb-height)/3);
  margin-right: 6vw;
  margin-left: auto;
  line-height: 1.1;
  pointer-events: none;
  height:auto;
  width: 100px;
  text-transform: uppercase;
  font-size: var(--type-size-h2);
  text-align: left;
  font-weight: 400;
  color:white;
}

#about .about-me-first a{
  padding-top: 10px;
  color:white;
}




#about .about-me-header span{
  position: absolute;
  top: -30px;
  left: -50px;
  z-index: 21;
  width:calc(40vw);
  height: .75px;
  opacity: 0;


  background-color: white;

}
#about .about-me-header{
  color: rgba(0,0,0,0);
  text-transform: uppercase;
  font-size:var(--type-size-h1-outline);
  letter-spacing: var(--letter-spacing);
  text-align: left;
  font-weight: 300;
  font-family: var(--type-fam-reg);
  position: relative;
  top: 20px;
  z-index: 21;
  margin-left: var(--marg);
  line-height: .9;
  width: 200px;
  -webkit-text-stroke: .75px white;
}


#about .blur{
  top:-70px;
  left: -200px;
  z-index: -1;
  height: 500px;
  width: 500px;
  position: absolute;
  opacity: .6
}

#about .contact-list{
  color:white;
  position: relative;
  margin-left: 35px;
  margin-top: 60px;
  font-family: var(--type-fam-serif);
  font-style: italic;
  font-size: 14pt;
  text-transform: lowercase;
  font-weight: 400;
  letter-spacing: .2px;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: calc(var(--thumb-width) - 40px);
  list-style:none;
  text-transform: uppercase;

}

#about .contact-list li{
  padding-top: 0px;
  display: block;
  flex: 0 1 auto;
  list-style-type: none;
}

#about .contact-list a{
  text-decoration: none;
  color: inherit;

}

#about .me-list{
  color:white;
  position: relative;
  margin-left: 0px;
  margin-top: 50px;
  font-family: var(--type-fam-serif);
  font-style: italic;
  font-size: 14pt;
  text-transform: lowercase;
  font-weight: 400;
  letter-spacing: .2px;
  display: flex;
  align-items: stretch;
  /*justify-content: space-between;*/
  width: 20vw;
  list-style:none;
  text-transform: uppercase;

}

#about .me-list li{
  padding-right: 50px;
  display: block;
  flex: 0 1 auto;
  list-style-type: none;


}

#about .me-list a{
  text-decoration: none;
  color: inherit;

}

#about .resume-archive {
  position: relative;
  font-family: var(--type-fam-reg);
  font-weight: 300;
  text-transform: uppercase;
  padding-top: 80px;
  font-size: 15pt;


}

#about .resume-archive a:nth-of-type(n) {
  padding-right: 50px;
  color: inherit;
}



#about .skills{
  font-family: 'titling-gothic-fb-extended', sans-serif;
  margin-top: 100px;
  position: relative;
  right: 0;
  margin-left: 50vw;
  text-transform: uppercase;
  height:auto;
  width: 50vw;
  font-size: 80pt;
  text-align: left;
  font-weight: 500;
  color:white;
  white-space: pre-line;

}

#about .skills p{
  letter-spacing: -5px;

}

#about .skills .mask{
  letter-spacing: -5px;
  background-color: var(--main-grey-color);
  width: 610px;

}

#about .skills .left{
  /*outline: 1px solid white;*/
  height: 70pt;
  width: 50vw;
  top: var(--block-top);
  left:auto;
  left: calc(-50vw - 0px);
  position: absolute;


}
#about .skills .right{
  /*outline: 1px solid white;*/
  height: 70pt;
  width: 280px;
  top: var(--block-top);
  left:auto;
  right: 0;
  position: absolute;
}

#about .skills .left .move-text{
  z-index: -1;
  position: absolute;
  top:-15px;
  width: 300vw;
  font-family: var(--type-fam-reg);
  color: rgba(255,255,255,0);
  font-size: 70pt;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255,250,240,1);
}
#about .skills .left .move-text::after{
  content: " SKILLS * SKILLS * SKILLS * SKILLS * SKILLS * SKILLS  * SKILLS SKILLS"
}
#about .skills ul{
  padding-left: 0;
  list-style: none;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'titling-gothic-fb-extended', sans-serif;
  line-height: 1.6;
  width: 50vw;
  font-size: 16pt;
  padding-bottom: 50px;
}

#about .skills ul p{
  letter-spacing: 0;
  margin-top: -30px;
  text-align: left;
  text-transform: lowercase;
  font-weight: 500;
  font-family: 'acumin-pro-wide', sans-serif;
  line-height: 1.6;
  width: 20vw;
  margin-right: 100px;
  margin-left: auto;
  font-size: 10pt;

}

#about .skills ul span{
  position: absolute;
  margin-left: -5px;
  height: 1px;
  width: calc(50vw - 100px);
  background-color: white;

}

#about .process{
  font-family: 'titling-gothic-fb-extended', sans-serif;
  margin-top: 100px;

  position: relative;
  right: 0;
  margin-left: 100px;
  text-transform: uppercase;
  height:auto;
  width: 900vw;
  font-size: 80pt;
  text-align: left;
  font-weight: 500;
  color:white;
  white-space: pre-line;

}


#about .process .left{
  height: 70pt;
  width: 50vw;
  top: var(--block-top);
  left:auto;
  left: calc(-50vw - 20px);
  position: absolute;
}
#about .process .mask{
  letter-spacing: -5px;
  background-color: var(--main-grey-color);
  width: 880px;
  pointer-events: none;
}
#about .process .left .move-text{
  z-index: -1;
  position: absolute;
  top:-15px;
  width: 300vw;
  font-family: var(--type-fam-reg);
  color: rgba(255,255,255,0);
  font-size: 70pt;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255,250,240,1);
}

#about .process .left .move-text::after{
  content: " PROCESS PROCESS PROCESS PROCESS PROCESS PROCESS ";
}

#about .process ul{
  padding-left: 0;
  list-style: none;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.6;
  width: 50vw;
  font-size: 16pt;
  padding-bottom: 50px;
}

#about .process ul p{
  margin-top: -30px;
  text-align: left;
  text-transform: lowercase;
  font-weight: 300;
  font-family: 'titling-gothic-fb', sans-serif;
  line-height: 1.6;
  width: 20vw;
  margin-right: 100px;
  margin-left: auto;
  font-size: 10pt;

}

#about .process ul span{
  position: absolute;
  margin-left: -5px;
  height: 1px;
  width: calc(50vw - 100px);
  background-color: white;

}



/*---------FOOTER-------------------*/
#footer {
  margin-top: -80px;
  padding-top: 0px;
  position: relative;
  padding-top: 50px;
  width: auto;
  height: auto;
  font-family: var(--type-fam-reg);
  font-size: 20pt;
  font-weight: 400;
  color: white;
}
#footer .top-button{
  position: absolute;
  height: var(--icon-size);
  width: var(--icon-size);
  right: 35px;
  top: -90px;
}
#footer .bar{
  position: absolute;
  left: 30px;
  height: 1px;
  width: calc(100vw - 60px);
  background-color: white;
}

#footer .copyright{
  position: relative;
  padding-top: 10px;
  margin-left: 40px;
  float: left;
}

#footer .footer-name{
  position: relative;
  padding-top: 10px;
  margin-left: auto;
  margin-right: 40px;
  float: right;
}

#footer i{
  font-family: var(--type-fam-serif);

}


#contact{
  width: 100vw;
  height: 100vh;

  padding-top: 200px;

}

#contact .contact-header {
  font-family: var(--type-fam-wide);
  font-size: 75pt;
  color: var(--main-grey-color);
  text-transform: uppercase;
  position: relative;
  margin-left: var(--marg-left);
  margin-top: -50px;
}

#contact .contact-header span{
  position: absolute;
  top:80px;
  right: 100px;
  width:270px;
  height: 3px;
  background-color: var(--main-grey-color);
}

#contact .contact-me{
  font-family: var(--type-fam-body);
  font-size: 25pt;
  color: var(--main-grey-color);
  width:42vw;
  position: relative;
  margin-left: 125px;
  margin-top: -30px;
  line-height: 1.1;
  font-weight: 500;
}
